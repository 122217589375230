/* eslint-disable i18next/no-literal-string */
// change also in pages.css

export interface ColorsType {
  background: string;
  backgroundSecondary: string;
  backgroundHover: string;
  backgroundActive: string;
  font: string;
  fontSecondary: string;
  fontHover: string;
  fontActive: string;
  border: string;
  borderActive: string;
  borderGlow: string;
  warning: string;
  success: string;
  error: string;
  primary: string;
  purple: string;
  black: string;
  white: string;
  tags: {
    primary: string;
  };
  candles: {
    wick: {
      green: string;
      red: string;
    };
    body: {
      green: string;
      red: string;
    };
    border: {
      green: string;
      red: string;
    };
  };
}

export type ColorModeType = "dark" | "light";

export const defaultColors: { [key in ColorModeType]: ColorsType } = {
  dark: {
    background: "#131722",
    backgroundSecondary: "rgba(255, 255, 255, 0.04)",
    backgroundHover: "#2a2e39",
    backgroundActive: "#111a2c",
    font: "#b2b5be",
    fontSecondary: "rgb(113, 113, 113)",
    fontHover: "#fff",
    fontActive: "rgb(108 147 255)",
    border: "#2a2e39",
    borderActive: "rgb(108 147 255)",
    borderGlow: "#1d1d5f",
    warning: "#faad14",
    success: "#5aef70",
    error: "#ff4d4f",
    primary: "#0d6efd",
    purple: "#800080",
    black: "#131722",
    white: "#b2b5be",
    // primary: "#722ED1",
    tags: {
      primary: "blue",
    },
    candles: {
      wick: {
        green: "rgb(178, 235, 242)",
        red: "rgb(103, 58, 183)",
      },
      body: {
        green: "rgb(178, 235, 242)",
        red: "rgb(103, 58, 183)",
      },
      border: {
        green: "rgb(178, 235, 242)",
        red: "rgb(103, 58, 183)",
      },
      // wick: {green: "#6BA583", red: "#DB0000"},
      // body: {green: "#6BA583", red: "#DB0000"},
      // border: {green: "#6BA583", red: "#DB0000"},
    },
  },
  light: {
    background: "#e3e3e3",
    backgroundSecondary: "rgba(0, 0, 0, 0.02)",
    backgroundHover: "rgba(0,0,0,.06)",
    backgroundActive: "#e6f4ff",
    font: "#000",
    fontSecondary: "#aaa",
    fontHover: "#000",
    fontActive: "#1e53e5",
    border: "#0000002B",
    borderActive: "#1e53e5",
    borderGlow: "#1d1d5f",
    warning: "#fb3",
    success: "#00c851",
    error: "#ff4d4f",
    primary: "#0d6efd",
    purple: "#800080",
    black: "#131722",
    white: "#131722",
    tags: {
      primary: "blue",
    },
    candles: {
      wick: {
        green: "rgb(178, 235, 242)",
        red: "rgb(103, 58, 183)",
      },
      body: {
        green: "rgb(178, 235, 242)",
        red: "rgb(103, 58, 183)",
      },
      border: {
        green: "rgb(178, 235, 242)",
        red: "rgb(103, 58, 183)",
      },
      // wick: {green: "#6BA583", red: "#DB0000"},
      // body: {green: "#6BA583", red: "#DB0000"},
      // border: {green: "#6BA583", red: "#DB0000"},
    },
  },
};
