/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 */

import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

import { backendRoutes } from "../../constants/backendConstants";
export default function NotFoundPage() {
  return (
    <div>
      <h1>
        <Trans i18nKey="notFoundPage.title" />
      </h1>
      <Link to={backendRoutes.frontendEntry}>
        <h2>
          <Trans i18nKey="notFoundPage.goHome" />
        </h2>
      </Link>
    </div>
  );
}
